import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faClipboard,
  faDollarSign,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { SupportImg } from "../assets";

const services = [
  {
    icon: faComments,
    title: "Live Chat Assistance",
    description:
      "Have a question? Need immediate guidance? We have round the clock support in live chat to aid you in any way possible.",
  },
  {
    icon: faClipboard,
    title: "Project Discussion and Planning",
    description:
      "We are a team of experts to advise and assist you on your projects and probably how best to plan your courses. Let’s improve your future, by working together to ensure you are headed in the right direction.",
  },
  {
    icon: faDollarSign,
    title: "Payment Upon Agreement",
    description:
      "Once this aspect is done, we are in a position to guide you into the payment process after we fully understand all the details concerning your project. In any business other than construction, once the payment is made the contractor starts the project.",
  },
  {
    icon: faClock,
    title: "Deadline Submission",
    description:
      "Don't stress about deadlines. Your assignments will be submitted for you on time thus freeing up your time for learning more.",
  },
];

const SupportSection = () => {
  return (
    <div className="container mx-auto py-16 px-4 md:flex md:items-center md:justify-between">
      <div className="md:w-1/2">
        <h2 className="text-4xl font-bold mb-4">
          Efficient Assistance for Your Achievement With the Help of a
          Professional Team of Online Class Assistants.
        </h2>
        <p className="text-gray-700 mb-6">
          Managing time is often a problem, let alone when students are involved
          in online education and actively working on their projects with the
          due date in mind. We know the pressure, that is why US Online Class
          Helper process is fast, precise and eager to adhere so that we do not
          derail.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <div key={index} className="flex items-start">
              <div className="bg-headerBg text-white p-4 rounded-full mr-4">
                <FontAwesomeIcon icon={service.icon} size="2x" />
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
        <img
          src={SupportImg}
          alt="Support"
          className="w-full max-w-sm rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
};

export default SupportSection;
