import React from "react";

const PromoBanner = () => {
  return (
    <div className="bg-headerBg text-white text-center py-16 px-4">
      <h2 className="text-4xl font-bold mb-4">
        Hire an Expert to Take Your Online Classes and Achieve the Grade You
        Need.
      </h2>
      <p className="text-lg mb-8">
        At US Online Class Helper, our privacy policy is100% perfect, that your
        information will not to be leaked. Thus, why wait or worry? Ring us this
        week and get a 70% discount on any service you want!
      </p>
      <div className="flex justify-center space-x-4">
        <button className="bg-white text-red-500 px-6 py-2 rounded hover:bg-gray-200">
          Live Chat
        </button>
        <button className="bg-white text-red-500 px-6 py-2 rounded hover:bg-gray-200">
        <a href="tel:346-264-2066">Call Us</a> 
        </button>
      </div>
    </div>
  );
};

export default PromoBanner;
