import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter ,faPaypal, faCcVisa, faCcMastercard, faCcAmex, faApplePay, faGooglePay, faCcDiscover } from '@fortawesome/free-brands-svg-icons';
import { faShieldAlt, faThumbsUp } from '@fortawesome/free-solid-svg-icons';


const Footer = () => (
  <footer className="bg-black text-white pt-10 pb-4">
    <div className="container mx-auto flex flex-wrap justify-between">
      <div className="w-full md:w-1/3 text-center md:text-left mb-6 md:mb-0">
        <img src={Logo} alt="Logo" className="mx-auto md:mx-0 max-w-36 mb-2" />
        <p className="text-sm mb-4">Your Path to Online Success Starts Here with US Online Class Helper</p>
        <div className="flex justify-center md:justify-start space-x-3">
      <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
        <FontAwesomeIcon icon={faFacebook} className="h-6 w-6" />
      </a>
      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-pink-600 hover:text-pink-800">
        <FontAwesomeIcon icon={faInstagram} className="h-6 w-6" />
      </a>
      <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600">
        <FontAwesomeIcon icon={faTwitter} className="h-6 w-6" />
      </a>
    </div>
      </div>
      <div className="w-full md:w-1/3 text-center mb-6 md:mb-0">
        <h3 className="font-bold mb-4">Useful Links</h3>
        <ul>
          <li className="mb-2"><Link to="/about" className="hover:text-gray-400">About</Link></li>
          <li className="mb-2"><Link to="/subjects" className="hover:text-gray-400">Subjects</Link></li>
          <li className="mb-2"><Link to="/sign-up" className="hover:text-gray-400">Sign Up</Link></li>
        </ul>
        <div className="flex justify-center space-x-2 mt-4">
      <FontAwesomeIcon icon={faPaypal} className="h-8 text-blue-600" />
      <FontAwesomeIcon icon={faCcVisa} className="h-8 text-blue-600" />
      <FontAwesomeIcon icon={faCcMastercard} className="h-8 text-red-600" />
      <FontAwesomeIcon icon={faCcAmex} className="h-8 text-blue-600" />
      <FontAwesomeIcon icon={faApplePay} className="h-8 text-black" />
      <FontAwesomeIcon icon={faGooglePay} className="h-8 text-green-600" />
      <FontAwesomeIcon icon={faCcDiscover} className="h-8 text-orange-600" />
    </div>
      </div>
      <div className="w-full md:w-1/3 text-center md:text-right">
        <h3 className="font-bold mb-4">Useful Links</h3>
        <ul>
          <li className="mb-2"><Link to="/TermsAndConditions" className="hover:text-gray-400">Terms & Conditions</Link></li>
          <li className="mb-2"><Link to="/privacy" className="hover:text-gray-400">Privacy Policy</Link></li>
        </ul>
        <div className="flex justify-center md:justify-end space-x-3 mt-4">
      <FontAwesomeIcon icon={faThumbsUp} className="h-12 text-green-500" alt="Satisfaction Guarantee" />
      <FontAwesomeIcon icon={faShieldAlt} className="h-12 text-blue-500" alt="100% Secure" />
    </div>
      </div>
    </div>
    <div className="bg-gradient-to-r from-headerBg to-headerBgDark py-4 mt-10">
      <div className="container mx-auto text-center text-sm">
        &copy; 2024 – US Online Class Helper. All Rights Reserved.
      </div>
    </div>
  </footer>
);

export default Footer;
