import React from 'react';

const DiscountFormModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;


  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={handleBackgroundClick}>
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full relative">
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &#10005;
        </button>
        <h2 className="text-2xl font-bold mb-4">Get Your Discount</h2>
        <p className="text-gray-700 mb-6">Fill out the form below to claim your discount!</p>
        <form>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-red-500"
              type="text"
              id="name"
              placeholder="Enter your name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-red-500"
              type="email"
              id="email"
              placeholder="Enter your email"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
              Phone
            </label>
            <input
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-red-500"
              type="tel"
              id="phone"
              placeholder="Enter your phone number"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="service">
              Service Interested In
            </label>
            <select
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-red-500"
              id="service"
            >
              <option>Online Class Help</option>
              <option>Assignment Help</option>
              <option>Exam Help</option>
            </select>
          </div>
          <button
            type="submit"
            className="w-full bg-headerBg text-white px-4 py-2 rounded-lg hover:bg-red-600"
          >
            Claim Discount
          </button>
        </form>
      </div>
    </div>
  );
};

export default DiscountFormModal;
