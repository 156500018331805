import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faClipboard,
  faQuestionCircle,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";

const servicesData = [
  {
    icon: faComments,
    title: "Discussion/Responses",
    description:
      "It is normal that online discussions can be stressful, now it is time to make things easier. Allow us to be your discussion partner as you engage in resourceful online classes; we shall be here learning with you",
  },
  {
    icon: faClipboard,
    title: "Assignments",
    description:
      "Are you searching the internet with such phrases as ‘’pay someone to take my class’’ because of the accumulating assignments? Oh, I get that; but don’t worry, you have us here. You need not worry; our helpful and professional assignment experts will assist in the organization of the workload to avoid the stress of deadline pressures.",
  },
  {
    icon: faQuestionCircle,
    title: "Quizzes",
    description:
      "Quiz success made simple. Struggling with quizzes? Don’t let those tests get the better of you, let our quiz specialists help you ace them. With our help, you will be able to answer all the quizzes and raise your grades for sure.",
  },
  {
    icon: faGraduationCap,
    title: "Exams",
    description:
      "Sweep all your exams with flying colours, with our assistance. Yes, preparing for exams can be very stressful at times. Let us be your exam buddies to help you study and whenever you are ready for the exam, you will be confident.",
  },
];

const Services = () => (
  <div className="container mx-auto py-16 px-6 text-center">
    <h2 className="text-4xl font-bold mb-12">
      Top Class Professional Online Class Helper At Very Cheap Prices.
    </h2>
    <div className="grid gap-8 md:grid-cols-4">
      {servicesData.map((service, index) => (
        <div
          key={index}
          className="flex flex-col items-center bg-white rounded-lg shadow-lg p-6 h-full hover:shadow-2xl transition-shadow duration-300"
        >
          <div
            className={`${
              index % 2 === 0 ? "bg-headerBg" : "bg-headerBgDark"
            } text-white p-4 rounded-full mb-4`}
          >
            <FontAwesomeIcon icon={service.icon} size="2x" />
          </div>
          <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
          <p className="text-gray-600">{service.description}</p>
        </div>
      ))}
    </div>
    <div className="flex justify-center mt-12 space-x-4">
      <button className="bg-headerBg text-white px-4 py-2 rounded hover:bg-red-600">
        Live Chat
      </button>
      <button className="bg-headerBgDark text-white px-4 py-2 rounded hover:bg-red-600">
      <a href="tel:346-264-2066">Call Us</a> 
      </button>
    </div>

    <div className="justify-center mt-12 space-x-4">
      <h1 className="text-lg font-extrabold">
        Qualified Tutor for your Child’s online Class at pocket-friendly prices.
      </h1>
      <p>
        Were you searching for “do my online class for me”? If yes, then you
        have visiting the perfect platform for it! To be specific, we offer help
        to Blackboard, Canvas, Instructure, MyLab & Mastering by Pearson,
        Pearson, Moodle, D2L Brightspace, and Zoom. Our experience means that
        you succeed in any online learning context.
      </p>
    </div>
  </div>
);

export default Services;
