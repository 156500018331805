import React from "react";
import { PromoImg } from "../assets";

const PromoSection = () => {
  return (
    <div className="container mx-auto py-16 px-4 md:flex md:items-center md:justify-between bg-gray-100 rounded-lg shadow-lg">
      <div className="md:w-1/2">
        <h2 className="text-4xl font-bold mb-4">
          Tell me, do you have a wish as to pay someone to ‘take’ a class for
          you?
        </h2>
        <h3 className="text-xl font-semibold text-red-600 mb-4">
          CONGRATULATION YOU ARE LUCKY PERSON AND YOUR WISH IS GOING TO
          CUMMULATE.
        </h3>
        <p className="text-gray-700 mb-6">
          Studying over for several years now, US Online Class Helper have been
          helping several students to help them to attain good grades, and all
          this within affordable prices!
        </p>
        <div className="flex space-x-4">
          <button className="bg-headerBg text-white px-6 py-2 rounded hover:bg-red-600">
            Live Chat
          </button>
          <button className="bg-headerBg text-white px-6 py-2 rounded hover:bg-red-600">
            Call Us
          </button>
        </div>
      </div>
      <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
        <img
          src={PromoImg}
          alt="Promo"
          className="w-full max-w-sm rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
};

export default PromoSection;
