import React from 'react'
import DiscountForm from '../components/DiscountForm'

export const Signup = () => {
  return (
    <div>
        
        <DiscountForm />
    </div>
  )
}
