import React, { useState } from 'react';
import DiscountFormModal from './DiscountFormModal';


const testimonials = [
  {
    id: 1,
    name: "Sarah J.",
    text: "US Online Class Helper ensured I aced my online classes. Highly recommend their expertise! They truly know their stuff.",
    rating: 5,
    logo: "A+", 
  },
  {
    id: 2,
    name: "John C.",
    text: "They took my online exams like pros, and I got the grades I needed. Thank you! Couldn't have done it without them.",
    rating: 5,
    logo: "A+", 
  },
  {
    id: 3,
    name: "Emily",
    text: "I was worried about my proctored exam, but they made it stress-free and secured my success. Great peace of mind!",
    rating: 5,
    logo: "A+", 
  },
  {
    id: 4,
    name: "Michael R.",
    text: "Amazing service! They helped me with my assignments and I scored excellent grades.",
    rating: 5,
    logo: "A+",
  },
  {
    id: 5,
    name: "Jessica T.",
    text: "I couldn’t have completed my online courses without their help. Highly recommended!",
    rating: 5,
    logo: "A+",
  },
  // Add more testimonials as needed
];

const Testimonials = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev === 0 ? Math.ceil(testimonials.length / 4) - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev === Math.ceil(testimonials.length / 4) - 1 ? 0 : prev + 1));
  };


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-gray-100 py-16 px-4 text-center">
      <h2 className="text-4xl font-bold mb-4">Approved Reviews from Our Precious Clients</h2>
      <button
        className="bg-headerBg text-white px-6 py-2 rounded mb-8 hover:bg-red-600"
        onClick={openModal}
      >
        Order Now
      </button>
      <div className="relative max-w-7xl mx-auto overflow-hidden">
        <div className="flex items-center justify-between">
          <button onClick={handlePrev} className="text-2xl px-4">&#8592;</button>
          <div className="flex space-x-4 transform transition-transform duration-500" style={{ transform: `translateX(-${currentSlide * 100}%)`, width: `${(testimonials.length / 4) * 100}%` }}>
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.id}
                className="w-1/4 p-4 bg-white rounded-lg shadow-md"
              >
                <div className="flex items-center justify-center mb-4">
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <span key={i} className="text-yellow-500">&#9733;</span>
                  ))}
                </div>
                <p className="mb-4">{testimonial.text}</p>
                <div className="flex justify-between items-center">
                  <p className="font-bold">{testimonial.name}</p>
                  <p className="font-bold text-red-500">{testimonial.logo}</p>
                </div>
              </div>
            ))}
          </div>
          <button onClick={handleNext} className="text-2xl px-4">&#8594;</button>
        </div>
        <div className="flex justify-center mt-4">
          {Array.from({ length: Math.ceil(testimonials.length / 4) }).map((_, index) => (
            <span
              key={index}
              className={`h-2 w-2 mx-1 rounded-full ${
                index === currentSlide ? 'bg-headerBg' : 'bg-gray-400'
              }`}
            />
          ))}
        </div>
      </div>
      <DiscountFormModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default Testimonials;
