import React from "react";
import { BgHero } from "../assets";
// Replace with actual path to the background image

const AcademicSuccess = () => {
  return (
    <div
      className="relative bg-cover bg-center py-16"
      style={{ backgroundImage: `url(${BgHero})` }}
    >
      <div className="container mx-auto bg-white bg-opacity-90 p-8 rounded-lg shadow-lg max-w-4xl">
        <h2 className="text-3xl font-bold mb-4">
          Maximizing Academic achievement with the cheapest online tutors.
        </h2>
        <p className="text-lg mb-6">
          Are you a struggling student who is in need of help in your studies
          because of tough classes that you are taking online? However, your
          search is over. Understandably, guidance and support is an essential
          aspect most importantly for the young learners. The objective of a
          satisfactory experience of the clients alone comes first and the fact
          that our company has been established for such long period gives a
          firm assurance towards the realization of this goal. The staff at
          academic writing help comprises of Ph. D. academic writers, academic
          editors, and Ph. D. researchers who devote themselves wholly to
          improving the experience of every learner who is taking online
          classes, finding online course tutor positions, receiving assistance
          with online classes or completing online assignments or any other
          subject level assistance, we have you covered.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6 text-center">
          <div>
            <p className="text-2xl font-bold text-red-500">370+</p>
            <p className="text-gray-700">Top-Scoring Professionals</p>
          </div>
          <div>
            <p className="text-2xl font-bold text-red-500">35,000+</p>
            <p className="text-gray-700">Requests Fulfilled</p>
          </div>
          <div>
            <p className="text-2xl font-bold text-red-500">99%</p>
            <p className="text-gray-700">Rate of Growth</p>
          </div>
        </div>
        <div className="flex justify-center space-x-4">
          <button className="bg-headerBg text-white px-6 py-2 rounded hover:bg-red-600">
            Live Chat
          </button>
          <button className="bg-headerBg text-white px-6 py-2 rounded hover:bg-red-600">
           <a href="tel:346-264-2066">Call Us</a> 
          </button>
        </div>
      </div>
    </div>
  );
};

export default AcademicSuccess;
