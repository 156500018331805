import React from 'react'
import Hero from '../components/Hero'
import Services from '../components/Services'
import BrandIcon from '../components/BrandIcon'
import PromoSection from '../components/PromoSection'
import SupportSection from '../components/SupportSection'
import FieldOfStudy from '../components/FieldOfStudy'
import PromoBanner from '../components/PromoBanner'
import AcademicSuccess from '../components/AcademicSuccess'
import Testimonials from '../components/Testimonials'
import ContactUsSection from '../components/ContactUsSection'

function Home() {
  return (
    <div>
        <Hero />
        <Services />
        <BrandIcon />
        <PromoSection />
        <SupportSection />
        <FieldOfStudy />
        <PromoBanner />
        <AcademicSuccess />
        <Testimonials />
        <ContactUsSection />
    </div>
  )
}

export default Home