import React from 'react';

const DiscountForm = () => {
  return (
    <div className="bg-white py-16 px-4 text-center shadow-md rounded-lg max-w-lg mx-auto">
      <h2 className="text-3xl font-bold text-red-500 mb-2">70% EXCLUSIVE DISCOUNT</h2>
      <p className="text-gray-700 mb-8">Our academic consultant will reach out to you soon.</p>
      <form className="space-y-4">
        <div className="flex flex-col sm:flex-row sm:space-x-4">
          <input
            className="w-full px-4 py-2 mb-4 sm:mb-0 border border-gray-300 rounded-lg focus:outline-none focus:border-red-500"
            type="text"
            placeholder="Enter name"
          />
          <input
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-red-500"
            type="text"
            placeholder="Contact Number"
          />
        </div>
        <input
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-red-500"
          type="email"
          placeholder="Enter email here"
        />
        <select
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-red-500"
        >
          <option value="" disabled selected>
            Services
          </option>
          <option value="service1">Service 1</option>
          <option value="service2">Service 2</option>
          <option value="service3">Service 3</option>
        </select>
        <textarea
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-red-500"
          placeholder="Tell us more about your project."
          rows="4"
        ></textarea>
        <button
          type="submit"
          className="w-full bg-headerBg text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300"
        >
          Sign Up
        </button>
      </form>
    </div>
  );
};

export default DiscountForm;
