import React, { useState, useEffect } from 'react';
import { BrandIcon1, BrandIcon2, BrandIcon3, BrandIcon4 } from '../assets';

// Brand icons array
const brandIcons = [
  { src: BrandIcon1, alt: 'Pearson' },
  { src: BrandIcon2, alt: 'Instructure' },
  { src: BrandIcon3, alt: 'Blackboard' },
  { src: BrandIcon4, alt: 'Zoom' },
];

// Duplicate the array to create a continuous loop effect
const duplicatedBrandIcons = [...brandIcons, ...brandIcons];

const BrandIcon = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => prevSlide + 1);
    }, 4000); // Change slide every 4 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentSlide >= brandIcons.length) {
      setTimeout(() => {
        setCurrentSlide(0);
      }, 1000); // Delay to reset slide to the start without notice
    }
  }, [currentSlide]);

  return (
    <div className="overflow-hidden my-4">
      <div
        className="flex transition-transform duration-1000 ease-in-out"
        style={{ transform: `translateX(-${(currentSlide * 100) / brandIcons.length}%)` }}
      >
        {duplicatedBrandIcons.map((icon, index) => (
          <div
            key={index}
            className="flex-shrink-0 mx-4" // Spacing between icons
            style={{ width: `${100 / brandIcons.length}%` }}
          >
            <img src={icon.src} alt={icon.alt} className="max-w-full" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandIcon;
