import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 font-sans text-gray-800">
      <h1 className="text-3xl font-bold mb-6 text-blue-900">Terms and Conditions</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-blue-900">Use Of The Website</h2>
        <p>
          Please read these Conditions of Use ("terms") carefully before using this website. Accessing, browsing, or using
          this website is a privilege subject to the following terms and indicates your agreement and acceptance of these
          terms. By using this website you acknowledge that you have read, understood, and agree to the terms.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-blue-900">Copyright/Trademark</h2>
        <p>
          All content that is included on this site, such as text, graphics, logos, button icons, images, digital downloads,
          data compilations, and software, is the property of or its content suppliers and protected by international
          copyright laws. The compilation of all content at this site is the exclusive property of this company and
          protected by international copyright laws. All software used at this site is the property of this company or its
          software suppliers and protected by international copyright laws. The trademark names used within our sites are
          the property of their respective company or its subsidiaries and cannot be used in connection with any product or
          service that is not part of that company.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-blue-900">Site Amendment Policies</h2>
        <p>
          We provide an unlimited amendments guarantee. Through this guarantee, customers can ask us for unlimited
          amendments and we will entertain the amendment request.
        </p>
        <p>
          In order to receive free amendments, customers are required to request a revision within 20 days of the order
          delivery date. However, in the case of reasonably large orders, the timeline exceeds up to 45 days. If the
          amendment request falls within these guidelines, we will revise the order to meet the customer's initial
          requirements free of charge.
        </p>
        <p>Once the above-mentioned timeline has passed, it shall be assumed that the customer is satisfied.</p>
        <p>
          All amendment requests pertaining to a customer's order will be completed as per the urgency of the order with 24
          hours as a standard minimum turnaround time.
        </p>

        <h3 className="text-xl font-semibold mt-6 mb-4 text-blue-900">Amendment Turnaround Time as per Urgency:</h3>
        <ul className="list-disc ml-6">
          <li>For orders up to 24 hours of urgency: Amendment turnaround time would be 24 hours.</li>
          <li>For orders between 24-48 hours of urgency: Amendment turnaround time would be 48 hours.</li>
          <li>For orders of 48 hours and above: Amendment turnaround time would be 72 hours.</li>
        </ul>
      </section>
    </div>
  );
};

export default TermsAndConditions;
